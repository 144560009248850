import algoliasearch from "algoliasearch";
const client = algoliasearch("C1BGFS3NLW", "b175f21c5432cf01757d129920cbc7fe");

window.onSearch = function onSearch (query) {
  const index = client.initIndex(`Lead_${getCurrentEnv()}`);
  return index.search(query).then(response => {
    const cleanedLeads = [];
    const hits = response.hits;
    hits.forEach(lead => {
      const leadLabel = lead.company_name ? lead.company_name : lead.listing_title;
      if (leadLabel) {
        cleanedLeads.push({
          label: leadLabel,
          id: lead.objectID,
        });
      }
    });
    return cleanedLeads;
  });
};

function getCurrentEnv () {
  return document.getElementsByTagName("body")[0].dataset.env;
}
